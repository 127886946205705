$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});


window.getLocationDetails = function(){
  var lat = $("#lat").val()
  var lng = $("#lng").val()

  var administrativeAreaLevel3 = $("#administrative_area_level_3").val()
  var administrativeAreaLevel2 = $("#administrative_area_level_2").val()
  var administrativeAreaLevel1 = $("#administrative_area_level_1").val()
  var state = ''
  var city = administrativeAreaLevel2
  city.length < 1 ? city = administrativeAreaLevel1 : state = administrativeAreaLevel1

  var locality = $("#locality").val()
  var sublocality = $("#sublocality").val()
  var streetNumber = $("#street_number").val()
  var countryShort = $("#country_short").val()
  var postalCode = $("#postal_code").val()
  return {
    "city": city,
    "state": state,
    "lat": lat,
    "lng": lng,
    "locality": locality,
    "sublocality": sublocality,
    "streetNumber": streetNumber,
    "countryCode": countryShort,
    "postalCode": postalCode
  }
}

window.setLocationDetails = function(){
  var details =  getLocationDetails()
  $("#order_page_city").val(details.city)
  $("#order_page_latitude").val(details.lat)
  $("#order_page_longitude").val(details.lng)
  $("#order_page_locality").val(details.locality)
  $("#order_page_sublocality").val(details.sublocality)
  $("#order_page_street_number").val(details.streetNumber)
  $("#order_page_country_code").val(details.countryCode)
  $("#order_page_zip").val(details.postalCode)
  $("#order_page_state").val(details.state)
}

window.updateBoxStatus = function(element){
  let boxStatus = $(element).val();
  if(boxStatus != '' && confirm('Are you sure you wants to update the status of Box. Please confirm.')){
    $(element).next('button').click();
  }
}

document.addEventListener("turbolinks:load", () => {
  $(".fromDate").change(function(){
    if($(this).val() == ''){
      $('.toDate').val('')
      $('.toDate').prop('disabled', true)
    }else{
      $('.toDate').prop('disabled', false)
    }
  });

  $(".fromDate").datepicker({
       format: 'mm/dd/yyyy',
       autoclose: true,
       endDate: "1d",
   }).on('changeDate', function (selected) {
       var minDate = new Date(selected.date.valueOf());
       $('.toDate').datepicker('setStartDate', minDate);
   });

  $(".toDate").datepicker({
       format: 'mm/dd/yyyy',
       autoclose: true,
       endDate: "1d",
  }).on('changeDate', function (selected) {
    var minDate = new Date(selected.date.valueOf());
    $('.fromDate').datepicker('setEndDate', minDate);
  });

  $(".datepicker").datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true,
    startDate: "0d",
    endDate: "+1Y"
   })

  $("#coupon_apply").change(function(){
    if(this.value == 'amount'){
      $('#coupon_amount_off').show()
      $('#coupon_percent_off').hide()
    }else{
      $('#coupon_amount_off').hide()
      $('#coupon_percent_off').show()
    }
  });
});